import { OVERLIMIT_FORM_HEADLINE, OVERLIMIT_PARAMETER_NAME, OVERLIMIT_PARAMETER_VALUE } from "../components/ContactForm/hooks/use-contact-form-overlimit-change";
import { useLocation } from "@reach/router"
import { useElementFocus } from "../focus/use-element-focus";
import { useEffect, useMemo } from "react";

export const useOverlimitLoadFocus = ({
    targetRef = undefined
}) =>
{

    const activeLocation = useLocation();
    const locationSearchParams = activeLocation.search;

    const isOverlimit = useMemo(() =>
    {
        const paramsParsed = new URLSearchParams(locationSearchParams);
        const overlimitParameter = paramsParsed.get(OVERLIMIT_PARAMETER_NAME);

        return overlimitParameter === OVERLIMIT_PARAMETER_VALUE;
    }, [locationSearchParams]);


    const { scheduleFocus } = useElementFocus({
        targetRef
    });


    useEffect(() =>
    {
        if (isOverlimit)
        {
            scheduleFocus();
        }

    }, [isOverlimit, scheduleFocus]);

    // zde by případně šlo použít i focusPerformed, ale
    // přijde mi čitelnější raději použít přímo isOverlimit
    // při případných změnách element focusu
    const formHeadlineText = isOverlimit ? OVERLIMIT_FORM_HEADLINE : undefined;

    return {
        formHeadlineText
    }

}