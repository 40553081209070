import React from "react";
import { Box } from "../style/Grid";
import { Heading } from "../style/Heading";
import { BigCompaniesOfferContainer } from "./styles/bigCompaniesOfferStyles";
import { BigCompaniesOfferItem } from "./bigCompaniesOfferItem";



export const BigCompaniesOffersList = () => {

    return (
        <Box className={"w-full flex"} py={20} px={20} pb={30} textAlign={"center"} backgroundColor={"white.100"} flexDirection="column">
            <Heading as="h2" fontSize={["28px", "36px"]} lineHeight="50px" mt="15px" mb="35px" fontWeight="700" color="green.dark">
                S produktem JISTOTA získáte
            </Heading>

            <BigCompaniesOfferContainer>
                <BigCompaniesOfferItem
                    title={"INDIVIDUÁLNÍ NABÍDKU"}
                    text={<>Kalkulaci na&nbsp;míru vašemu odběru a&nbsp;podle&nbsp;aktuálních tržních cen.</>}
                    iconSrc={require("../images/vetsi-firmy-calculator.svg").default}
                    itemIndex={0}
                />
                <BigCompaniesOfferItem
                    title={"PEVNOU CENU ENERGIÍ"}
                    text={<>Fixní obchodní cenu na&nbsp;kalendářní rok nebo déle pro&nbsp;lepší plánování nákladů vašeho podnikání.</>}
                    iconSrc={require("../images/vetsi-firmy-fix-price.svg").default}
                    itemIndex={1}
                />
                <BigCompaniesOfferItem
                    title={"ENERGETICKÉ PORADENSTVÍ"}
                    text={<>Hlídáme za&nbsp;vás vývoj cen energií a&nbsp;před koncem fixace vám včas předložíme novou nabídku.</>}
                    iconSrc={require("../images/vetsi-firmy-bulb.svg").default}
                    itemIndex={2}
                />
            </BigCompaniesOfferContainer>

        </Box>
    )

}