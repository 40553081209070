import React from "react";
import { FirstRowImg, FirstRowOfferText, SecondRowOfferText, SecondRowFiller, SmallWrapper, SmallWrapperItem } from "./styles/bigCompaniesOfferStyles";

export const BigCompaniesOfferItem = ({
    title,
    text,

    iconSrc,

    itemIndex,
}) =>
{

    const commonProps = {
        itemIndex,
    }

    const imgComponent =
        <FirstRowImg
            {...commonProps}
            src={iconSrc}
            alt=""
            className={"mb-0"}
        />;

    const titleOfferText = <FirstRowOfferText
        {...commonProps}
    >
        {title}
    </FirstRowOfferText>;

    const descriptionOfferText = <SecondRowOfferText
        {...commonProps}
    >
        {text}
    </SecondRowOfferText>;

    return (
        <>
            {imgComponent}
            {titleOfferText}


            <SecondRowFiller
                {...commonProps}
            />

            {descriptionOfferText}


            <SmallWrapper>
                <SmallWrapperItem row>
                    {imgComponent}
                </SmallWrapperItem>

                <SmallWrapperItem>
                    {titleOfferText}
                    {descriptionOfferText}
                </SmallWrapperItem>
            </SmallWrapper>

        </>
    );
}