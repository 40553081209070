import React from "react";
import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";

export const FORM_CONTACT_FORM_WRAPPER_ID = "kontakt";

const PhoneContactFormWrapper = ({ headline = "Získejte výhodné energie od MND!", children, wrapperRef = undefined }) =>
{
  return (
    <Wrapper
      position={"relative"}
      background={["white.100", `#a7be32 url(${require("../../images/contactForm__bg.jpg").default}) no-repeat bottom center`]}
      p={["30px 0", "60px 0 40px"]}
      id={FORM_CONTACT_FORM_WRAPPER_ID}
      className="scroll-my-[52px]"
      ref={wrapperRef}
    >
      <Container>
        <Flex flexDirection="column" alignItems={["flex-start", "center"]} width={1} mb={[0, 50]}>
          <Heading as="h2" fontSize={["26px", null, "36px", null]} fontWeight="700" color={["green.dark", "white.100"]}>
            {headline}
          </Heading>
        </Flex>
        {children}
      </Container>
    </Wrapper>
  );
};

export default PhoneContactFormWrapper;
