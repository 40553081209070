import React from "react";
import { ConsumptionBannerContent } from "./ConsumptionBannerContent";

export const CompaniesOfferBanner = ({
    title,
    subtitle,
    gasConsumptionText,
    eleConsumptionText,
    breadcrumbsName,
    renderAfterBanner,

    bannerSmallImageURL,
    bannerImageURL,

    customStyles = undefined,

    altSizing = undefined,

    consumptionBlur = undefined,
}) =>
{
    return (
        <>
            <div className={"relative bg-resource-green-100 pb-10"}>
                <div className={`
                    relative
                    bg-exploratory-green-600
                    bg-left-bottom
                    md:bg-bottom
                    bg-no-repeat
                    bg-cover
                    md:bg-auto
                    hp-image-container

                    alt-image-container
                `}>
                    <div className={"hidden sm:block absolute top-0 left-0 right-0 bottom-0 h-full mb-0"}>
                        <img
                            src={bannerImageURL}
                            className={"w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover mb-0"}
                            alt={"Získejte jistotu dodávky od MND"}
                        />
                    </div>
                    <div className={"block sm:hidden absolute top-0 left-0 right-0 bottom-0 h-full mb-0"}>
                        <img
                            src={bannerSmallImageURL}
                            className={"h-full w-full object-cover"}
                            alt={"Získejte jistotu dodávky od MND"}
                        />
                    </div>

                    <ConsumptionBannerContent
                        title={title}
                        subtitle={subtitle}

                        gasConsumptionText={gasConsumptionText}
                        eleConsumptionText={eleConsumptionText}

                        customStyles={customStyles}

                        altSizing={altSizing}

                        consumptionBlur={consumptionBlur}

                        breadcrumbsName={breadcrumbsName}
                    />
                </div>
                <div className={"relative z-50"}>
                    <div className={"container mx-auto max-w-screen-lg px-[20px] -mt-20 relative"}>
                        {renderAfterBanner()}
                    </div>
                </div>
            </div>
        </>
    )
};
