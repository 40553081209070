import { useState, useEffect, useCallback } from "react";

export const useElementFocus = ({
    targetRef = undefined
}) =>
{
    const targetRefCurrent = targetRef?.current;

    const [focusTracker, setFocusTracker] = useState(undefined);

    /**
     * Zda byl **poslední** focus proveden.
     */
    const focusPerformed = focusTracker && focusTracker.performedFocus;

    useEffect(() =>
    {
        // inicializace není dokončená
        if (!targetRefCurrent)
        {
            return;
        }


        if (!focusTracker || focusTracker.performedFocus)
        {
            return;
        }

        targetRefCurrent.scrollIntoView({ behavior: "smooth" });

        setFocusTracker((prev) => ({
            ...prev,
            performedFocus: true,
        }));

    }, [targetRefCurrent, focusTracker]);



    /**
     * Provede focus na element při dalším renderu.
     * 
     * Pokud element ještě nebyl v tu dobu inicializován,
     * provede ho ve chvíli, kdy inicializován bude.
     */
    const scheduleFocus = useCallback(() =>
    {
        setFocusTracker({
            performedFocus: false,
        })
    }, []);


    return {
        scheduleFocus,
        focusPerformed
    }

}