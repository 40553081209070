import styled from "styled-components";

export const ConsumptionBlur = styled.div`
    position: absolute;

    left: 0;
    top: 0;

    width: 600px;
    height: 600px;

    transform: translate(-50%, -50%);


    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    filter: blur(120px);

    z-index: 10;
`;