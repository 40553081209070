import { useCallback } from "react";
import { navigate } from "gatsby";

export const OVERLIMIT_FORM_HEADLINE = "Vaše podnikání si zaslouží individuální přístup.";
export const OVERLIMIT_PARAMETER_NAME = "overlimit";
export const OVERLIMIT_PARAMETER_VALUE = "1";

export const useContactFormOverlimitChange = ({
    targetUrl = undefined,
} = {}) =>
{

    const onOverlimitSubmit = useCallback(() =>
    {
        const params = new URLSearchParams();
        params.append(OVERLIMIT_PARAMETER_NAME, OVERLIMIT_PARAMETER_VALUE);
        const fullUrl = `${targetUrl}?${params.toString()}`;

        navigate(fullUrl);
    }, [targetUrl]);

    const handleFormOverlimitCalculationSubmit = useCallback(({
        isCommodityConsumptionValid
    }) =>
    {
        // Podle čistého původního příznaku isValid zjistíme, jestli jsme šli přes limit
        if (!isCommodityConsumptionValid)
        {
            onOverlimitSubmit();
            return;
        }
    }, [onOverlimitSubmit]);

    return {
        handleFormOverlimitCalculationSubmit,
    }
}