import React from "react";
import { Heading } from "../style/Heading";
import { MarkupToHtml } from "../helpers/parse";
import { Text } from "../style/Text";
import { Image } from "../style/Image";
import { ConsumptionBlur } from "./ConsumptionBlur";
import { Box } from "../style/Grid";
import BreadcrumbsV2 from "./breadcrumbsV2";


const YEARLY_CONSUMPTION = "Roční spotřeba:";


export const ConsumptionBannerContent = ({
    title,
    subtitle = undefined,
    gasConsumptionText,
    eleConsumptionText,

    customStyles = undefined,

    altSizing = false,
    consumptionBlur = false,

    breadcrumbsName
}) =>
{

    const titleComponent = typeof title === "string" ? <MarkupToHtml>
        {title}
    </MarkupToHtml> : title;

    const gasIcon = <Image src={require("../images/gas-light.svg").default} loading="lazy" height={"20px"} mr={"5px"} position={"relative"} verticalAlign={"text-bottom"} />;

    const eleIcon = <Image src={require("../images/electricity-light.svg").default} loading="lazy" height={"20px"} mr={"5px"} position={"relative"} verticalAlign={"text-bottom"} />;

    const mainTitleDesktopMB = subtitle ? undefined : "26px";

    const smallFontSizeHeading = altSizing ? "28px" : "28px";

    // figma je pro alternativní velikost zavádějící
    // je tam psáno stejný line height 38px ale ve výsledku má dvouřádkový text 66px
    // a s lineHeight 38px to vypadá jinak
    const smallLineHeightHeading = altSizing ? "33px" : "38px";

    const smallHeadingMB = altSizing ? "28px" : "24px";

    return (
        <div className={"relative mx-auto max-w-screen-lg"}>
            {/* desktop align podle figmy 97 - padding 20 => 77px */}
            <Box position="absolute" className={`z-50 left-[20px] xl:left-[-77px] right-[20px] top-[16px] sm:top-[0px]`}>
                    <BreadcrumbsV2
                    pages={[
                        {
                            name: breadcrumbsName,
                            link: null,
                        },
                    ]}
                    color={"white.100"}
                    homepageTitle="Úvod"
                    fontWeight="600"
                />
            </Box>

            <div className={`relative mw-full flex flex-col md:relative px-[20px] min-h-[430px] max-w-[732px] hidden sm:block ${customStyles?.content?.wrapper?.additive ?? ""}`}>
                <div className={"absolute top-[72px]"}>
                    <Heading as="h2" fontSize={"42px"} lineHeight={"52px"} fontWeight="700" color="white.100" mb={mainTitleDesktopMB}>
                        {titleComponent}
                    </Heading>
                    {subtitle ? <Heading as="h2" fontSize={"22px"} lineHeight={"44px"} mb="14px" fontWeight="700" color="white.100">
                        <MarkupToHtml>
                            {subtitle}
                        </MarkupToHtml>
                    </Heading> : null}
                    <Text color="white.100" fontSize={"18px"} lineHeight={"24px"} fontWeight={500} textDecoration="none" mb={"16px"}>
                        {YEARLY_CONSUMPTION}
                    </Text>
                    <Text color="white.100" fontSize={"18px"} lineHeight={"24px"} fontWeight={500} textDecoration="none" mb={"8px"}>
                        {gasIcon}
                        {gasConsumptionText}
                    </Text>
                    <Text color="white.100" fontSize={"18px"} lineHeight={"24px"} fontWeight={500} textDecoration="none">
                        {eleIcon}
                        {eleConsumptionText}
                    </Text>
                </div>
            </div>

            {/* Menší zařízení */}
            <div className={"relative mw-full flex flex-col px-[20px] min-h-[378px] sm:hidden"}>

                {consumptionBlur ? <ConsumptionBlur /> : null}

                <div className={`z-20 block w-full pt-[66px] pb-100px ${customStyles?.content?.wrapperSmall?.additive ?? ""}`}>
                    <Heading as="h1" fontSize={smallFontSizeHeading} lineHeight={smallLineHeightHeading} fontWeight="700" color="white.100" mb={smallHeadingMB}>
                        {titleComponent}
                    </Heading>

                    <Text color="white.100" fontSize={"16px"} lineHeight={"24px"} fontWeight={500} textDecoration="none" mb={"8px"}>
                        {YEARLY_CONSUMPTION}
                    </Text>
                    <Text color="white.100" fontSize={"16px"} lineHeight={"24px"} fontWeight={500} textDecoration="none" mb={"3px"}>
                        {gasIcon}
                        {gasConsumptionText}
                    </Text>
                    <Text color="white.100" fontSize={"16px"} lineHeight={"24px"} fontWeight={500} textDecoration="none">
                        {eleIcon}
                        {eleConsumptionText}
                    </Text>
                </div>

            </div>
        </div>
    )
}