
import styled, { css } from "styled-components";
import { Text } from "../../style/Text";

export const COLUMNS_PER_OFFER_ITEM = 2;


const IMG_SIZE = 50;
const IMG_PADDING_RIGHT = 16;

const IMG_COMPLETE_SIZE = IMG_SIZE + IMG_PADDING_RIGHT;

export const getFlipBreakpoint = (props) =>
{
    return props.theme.breakpoints.md;
}

export const getItemBaseOffset = (props) =>
{
    return (props.itemIndex * COLUMNS_PER_OFFER_ITEM) + 1;
}

export const BigCompaniesOfferContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    row-gap: 14px;

    @media (min-width: ${getFlipBreakpoint}) {
        display: grid;
        grid-template-columns: repeat(3, ${IMG_COMPLETE_SIZE}px 1fr);
        row-gap: unset;
    }
    
`;

export const HideOnSmall = css`
    @media (max-width: ${getFlipBreakpoint}) {
        display: none;
    }
`;


export const FirstRowCSS = css`

    grid-row-start: ${props => getItemBaseOffset(props)};

    @media (min-width: ${getFlipBreakpoint}) {
        grid-row-start: 1;   
    }
`;

export const SecondRowCSS = css`
    grid-row-start: 2;

    @media (max-width: ${getFlipBreakpoint}) {
        grid-row-start: ${props => getItemBaseOffset(props) + 1};
    }
`;

export const ColumnMainContent = css`
    grid-column-start: span 1;
`;


export const FirstRowImg = styled.img`
    ${HideOnSmall}
    ${FirstRowCSS}
    ${ColumnMainContent}

    flex-shrink: 0;
    flex-grow: 0;

    width: ${IMG_COMPLETE_SIZE}px;
    flex-basis: ${IMG_COMPLETE_SIZE}px;

    padding-right: ${IMG_PADDING_RIGHT}px;
`;

export const FirstRowOfferText = styled(Text)`
    ${HideOnSmall}
    ${FirstRowCSS}
    ${ColumnMainContent}

    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    color: ${props => props.theme.colors.green.dark};
    text-align: left;

    margin-bottom: 7px;


    @media (min-width: ${getFlipBreakpoint}) {
        display: flex;
        flex-direction: column;
    }
`;

export const SecondRowFiller = styled.div`
    ${HideOnSmall}
    ${SecondRowCSS}
`;



export const SecondRowOfferText = styled(Text)`
    ${SecondRowCSS}
    ${ColumnMainContent}
    ${HideOnSmall}

    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    color: ${props => props.theme.colors.green.dark};
    text-align: left;

    margin-bottom: 14px;

    @media (max-width: ${getFlipBreakpoint}) {
        margin-bottom: 0;
    }
`;


export const SmallWrapper = styled.div`
    display: flex;
    flex-direction: row;

    @media (min-width: ${getFlipBreakpoint}) {
        display: none;
    }
`;


export const SmallWrapperItem = styled.div`

    display: flex;
    flex-direction: column;

    ${props =>
    {
        const isRow = props.row;

        if (!isRow)
        {
            return ``;
        }


        return `
            flex-direction: row;
            align-items: flex-start;

            flex-shrink: 0;
        `;
    }}

    * {
        display: block;
    }
`;