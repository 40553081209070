import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import PhoneContactFormWrapper from "../../components/LeadForms/PhoneContactFormWrapper";
import { CompaniesOfferBanner } from "../../components/companiesOfferBanner";
import ContactForm from "../../components/contactForm";
import WhyMND from "../../components/whyMND";
import MainLayout from "../../componentsTailwind/MainLayout";
import config from "../../configs";
import { BigCompaniesOffersList } from "../../components/bigCompaniesOffersList";
import { useOverlimitLoadFocus } from "../../overlimit/use-overlimit-load-focus";

const LARGER_COMPANIES_SOURCE_ID = 332;


const bannerImageURL = require("../../images/vetsi-firmy-large.png").default;
const bannerSmallImageURL = require("../../images/vetsi-firmy-small.png").default;

export default ({}) =>
{
  const SEO = {
    title: "MND | Energie - plyn a elektřina za výhodné ceny",
    keywords: "dodavatel plynu, dodavatel elektřiny, dodavatel energií, srovnání cen plynu, srovnání cen elektřiny, cena plynu, cena elektřiny, změna dodavatele",
    description: "Získejte výhodnou cenu energií od stabilního a férového dodavatele elektřiny a plynu. Změna dodavatele energií jde s MND jednoduše online.",
  }

  const subtitleText = "z kategorie maloodběratel";

  const bannerStartTitle = "Nabídka pro větší firmy";
  const bannerTitle = <>{bannerStartTitle}<span class="inline sm:hidden leading-[30px] text-[22px]"><br />{subtitleText}</span></>;

  const contactFormWrapperRef = useRef(null);

  const { formHeadlineText } = useOverlimitLoadFocus({
    targetRef: contactFormWrapperRef
  });

  const renderAfterBanner = () => <BigCompaniesOffersList />;

  return (
    <MainLayout
      headerConfig={{
        topPanel: true,
        mainPanel: true,
      }}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="keywords" content={SEO.keywords} />
        <meta name="description" content={SEO.description} />
        <meta name="og:description" content={SEO.description} />
        <meta property="og:image" content={`${config.serverUrl}/images/og-image-hp.png`} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Homepage', section: 'Gas | Electricity' });
        `}</script>
        <link rel="canonical" href="https://www.mnd.cz/"></link>
      </Helmet>

      <div>
        <CompaniesOfferBanner
          title={bannerTitle}
          breadcrumbsName={bannerStartTitle}
          subtitle={subtitleText}
          gasConsumptionText="Plyn nad 63 MWh/rok"
          eleConsumptionText="Elektřina nad 30 MWh/rok"
          renderAfterBanner={renderAfterBanner}

          bannerImageURL={bannerImageURL}
          bannerSmallImageURL={bannerSmallImageURL}

          altSizing
        />
      </div>

      <PhoneContactFormWrapper
        wrapperRef={contactFormWrapperRef}
        headline={formHeadlineText}
      >
        <ContactForm
          enableEmail={false}
          type="PAGE_HP"
          analytics={{
            type: "Lead",
            text: "Ozveme se vám",
            section: "SMB",
            sourceId: LARGER_COMPANIES_SOURCE_ID,
          }}
          leadsource={LARGER_COMPANIES_SOURCE_ID}
          headline={"Zanechte nám kontakt. Ozveme se vám a zašleme zdarma nezávaznou individuální nabídku."}
          bottomText={'Nebo nám zašlete poptávku na email: <a href="mailto:firmy@mnd.cz.">firmy@mnd.cz</a>.'}
        />
      </PhoneContactFormWrapper>

      <div>
        <WhyMND bg={"white.100"} />
      </div>
    </MainLayout>
  );
};
